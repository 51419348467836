.field-text-editor {
  outline: none !important;
  min-height: 5rem;
  width: 100%;
  margin-left: -0.5rem;
}

.field-text-editor p {
  font-size: 0.9rem;
}

.field-text-editor h2 {
  font-size: 1.1rem;
  font-weight: 600;
}

.field-text-editor h3 {
  font-size: 1.3rem;
  font-weight: 600;
}
